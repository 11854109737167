import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Lightbox from 'yet-another-react-lightbox';
import Counter from "yet-another-react-lightbox/plugins/counter";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import 'yet-another-react-lightbox/styles.css';
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/counter.css";
import './ContainerPhotos.css';
import { useTranslation } from 'react-i18next';
import ReactPixel from 'react-facebook-pixel';
import { useCookies } from 'react-cookie';


/**
 * ContainerPhotos Component
 * 
 * The ContainerPhotos component displays a collection of images for a given apartment. It provides a responsive 
 * layout that includes a main image, a secondary image, and a third image with an option to view all images in a 
 * lightbox modal. The component also handles image loading states and cookie consent for loading images.
 * 
 * Key Features:
 * 
 * 1. **Responsive Layout**:
 *    - Uses Bootstrap's `Container`, `Row`, and `Col` to create a responsive grid layout for displaying images.
 * 
 * 2. **Image Loading**:
 *    - Implements a blur transformation for images to improve loading performance. Actual images load when the user 
 *      consents to cookies.
 * 
 * 3. **Lightbox Integration**:
 *    - Provides a lightbox modal to view all images with zoom, counter, and thumbnails plugins.
 * 
 * 4. **Internationalization**:
 *    - Uses `react-i18next` for text localization, allowing for dynamic language changes.
 * 
 * 5. **Cookie Consent**:
 *    - Checks for cookie consent before loading images, displaying placeholder text if consent is not given.
 * 
 * Props:
 * - `apartment`: An object containing an array of image URLs.
 * - `navbarHeight`: The height of the navigation bar, used to adjust the top padding of the container.
 */

export default function ContainerPhotos({ apartment, navbarHeight }) {
    const [cookies] = useCookies(['_iub_cs-s3824551']);

    // Translation hook for internationalization
    const { t } = useTranslation();
    
    // State for handling lightbox visibility and image index
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
    
    return (
        <div className="d-none d-lg-block">
            <Container 
                fluid 
                className='container-photos' 
                style={{
                    paddingTop: `calc(${navbarHeight}px)`,
                    paddingBottom: "20px",
                    background: `linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url(${apartment.images[0]})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                }}
            >
                <Row>
                    <Col xs={9}>
                        <img 
                            src={apartment.images[0]} 
                            alt="1"
                        />
                    </Col>
                    <Col xs={3} className="col-3-images">
                        <img 
                            src={apartment.images[1]} 
                            alt="2" 
                        />
                            <div className="image-container">
                            <img 
                                src={apartment.images[2]} 
                                alt="3" 
                            />
                            <Button 
                                variant="light" 
                                className="see-all-photos-button"
                                onClick={() => {
                                    if (cookies['_iub_cs-s3824551'] && cookies['_iub_cs-s3824551'].purposes[5]) {
                                        ReactPixel.trackCustom('ViewPhotos', { apartmentName: apartment.name });
                                    }
                                    setIsOpen(true);
                                }}
                            >
                                {t('see_all_photos')}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>

            {isOpen && (
                <Lightbox
                    slides={apartment.images.map(image => ({ src: image }))}
                    open={isOpen}
                    close={() => setIsOpen(false)}
                    index={photoIndex}
                    onIndexChange={setPhotoIndex}
                    plugins={[Counter, Thumbnails, Zoom]}
                    counter={{ container: { style: { top: 0 } } }}
                />
            )}
        </div>
    );
}