import { useMemo } from 'react';

/**
 * useFilteredApartments
 * 
 * This custom hook filters and sorts apartments based on the selected filters.
 * 
 * @param {Array} apartments - List of available apartments.
 * @param {Object} filters - Object containing the selected filters.
 * @param {Array} priorityApartments - List of priority apartments to be sorted first.
 * 
 * @returns {Array} List of filtered and sorted apartments.
 */

const priorityApartments = [
    'La Tessella', 
    'Palazzo Stupino - La Torre',
    'Palazzo Stupino - La Scala',
    'Palazzo Stupino - La Corte',
    'Casa Conca',
    'Vescovado\'s Suite', 
    'Casa Matilla - Verde',
    'Casa Matilla - Blu',
    'Dimora San Teobaldo',
    'Piazza Michele Ferrero - sulla piazza centrale',
    'C8 Apartments: Azzurro Mare 01',
    'C8 Apartments: Giallo Canarino 02',
    'C8 Apartments: Rosso Rubino 03'
];

export default function useFilteredApartments(apartments, filters) {
  const { selectedStates, totalGuests, pets, selectedOption } = filters;

  return useMemo(() => {
    // Filter apartments
    const filteredApartments = apartments.filter(apartment => {
      const isStateMatch = selectedStates.length === 0 || selectedStates.includes(apartment.city);
      const isGuestMatch = apartment.guests >= totalGuests;
      const isPetsMatch = pets === 0 || apartment.options.includes("Pets-friendly");
      const isOptionMatch = selectedOption === "All-location" || apartment.options.includes(selectedOption);
      return isStateMatch && isGuestMatch && isPetsMatch && isOptionMatch;
    });

    // Sort priority apartments
    const prioritySortedApartments = filteredApartments.filter(apartment =>
      priorityApartments.includes(apartment.name)
    );

    // Filter non-priority apartments
    const remainingApartments = filteredApartments.filter(apartment =>
      !priorityApartments.includes(apartment.name)
    );

    // Sort priority apartments according to the priority list
    const orderedPriorityApartments = priorityApartments
      .map(name => prioritySortedApartments.find(apartment => apartment.name === name))
      .filter(Boolean);

    // Shuffle remaining apartments
    const shuffledRemainingApartments = [...remainingApartments].sort(() => Math.random() - 0.5);

    // Combine ordered and random apartments
    return [...orderedPriorityApartments, ...shuffledRemainingApartments];
  }, [apartments, selectedStates, totalGuests, pets, selectedOption]);
}