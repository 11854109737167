import React from 'react';
import './Apartments.css';
import { Card } from 'react-bootstrap';
import { IoBedSharp, IoPeople, IoLogoEuro } from "react-icons/io5";
import { FaShower } from "react-icons/fa6";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useTranslation } from 'react-i18next';

/**
 * ApartmentCard Component
 * 
 * This component is responsible for displaying a single apartment's details within a card format. 
 * It includes a carousel for displaying apartment images, and information such as city, name, 
 * number of beds, bathrooms, and guest capacity.
 * 
 * Key Features:
 * 
 * 1. **Image Carousel**:
 *    - Uses `react-multi-carousel` to display apartment images in a swipeable, draggable carousel.
 *    - Custom arrows for navigation are provided to match the design.
 *    - The carousel displays images only if the user has consented to cookies; otherwise, a placeholder is shown.
 * 
 * 
 * 2. **Responsive Design**:
 *    - The carousel configuration is responsive, adapting to different screen sizes (desktop, tablet, mobile).
 * 
 * 3. **Card Click Handling**:
 *    - Clicking on the card opens a new page with detailed apartment information, unless the click occurs on carousel arrows or dots.
 */

export default function ApartmentCard({ id, city, name, beds, bathrooms, guests, images, price }) {

    const { t } = useTranslation();



    // Configuration for react-multi-carousel
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            slidesToSlide: 1 // Slide one item at a time
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1
        }
    };

    // Custom left arrow component for the carousel
    const CustomLeftArrow = ({ onClick }) => (
        <IoIosArrowBack
            className="custom-left-arrow"
            onClick={(event) => {
                event.stopPropagation(); // Prevent triggering the card click event
                onClick();
            }}
        />
    );

    // Custom right arrow component for the carousel
    const CustomRightArrow = ({ onClick }) => (
        <IoIosArrowForward
            className="custom-right-arrow"
            onClick={(event) => {
                event.stopPropagation(); // Prevent triggering the card click event
                onClick();
            }}
        />
    );

    // Handle the card click event to open a new page
    const handleCardClick = (event) => {
        // Check if the click happened on the carousel arrows or dots
        if (event.target.closest('.custom-left-arrow') ||
            event.target.closest('.custom-right-arrow') ||
            event.target.closest('.react-multi-carousel-dot')) {
            return;
        }
        // Open the apartment details page in a new tab
        window.open(`/apartment/${id}`, '_blank');
    };

    return (
        <Card className='apartment-card' onClick={handleCardClick}>
            <Carousel 
                responsive={responsive}
                infinite={true}
                swipeable={true}
                draggable={true}
                showDots={true}
                customLeftArrow={<CustomLeftArrow />} // Use custom left arrow
                customRightArrow={<CustomRightArrow />} // Use custom right arrow
                className='apartment-carousel'
            >
                {images.slice(0, 5).map((image, index) => (
                    <div key={index}>
                        <img
                            className="d-block w-100 apartment-img"
                            src={image}
                            alt={`Slide ${index}`}
                            loading={index === 0 ? 'eager' : 'lazy'}
                        />
                    </div>
                ))}
            </Carousel>
            <Card.Body>
                <div className='apartment-city'>
                    {city}
                </div>
                <div className='apartment-name'>
                    {name}
                </div>
                <div className='apartment-info'>
                    <div>
                        <IoBedSharp className='apartment-icon'/>
                        <span>{beds}</span>
                    </div>
                    <div>
                        <FaShower className='apartment-icon'/>
                        <span>{bathrooms}</span>
                    </div>
                    <div>
                        <IoPeople className='apartment-icon'/>
                        <span>{guests}</span>
                    </div>
                    <div>
                        <IoLogoEuro className='apartment-icon'/>
                        <span>{`> ${price}/${t('night')}`}</span>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
}