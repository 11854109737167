import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import Lightbox from 'yet-another-react-lightbox';
import Counter from "yet-another-react-lightbox/plugins/counter";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import 'yet-another-react-lightbox/styles.css';
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/counter.css";
import './MobileContainerPic.css';
import { useTranslation } from 'react-i18next';
import ReactPixel from 'react-facebook-pixel';
import { useCookies } from 'react-cookie';

/**
 * MobileContainerPic Component
 * 
 * The MobileContainerPic component provides a mobile-optimized view of apartment images. It includes a main image 
 * and two smaller images displayed in a grid layout. Users can view a larger version of the images in a lightbox modal.
 * The component also handles image loading states and applies blur transformations for performance optimization.
 * 
 * Key Features:
 * 
 * 1. **Mobile-Optimized Layout**:
 *    - Uses Bootstrap's `Row` and `Col` to create a responsive layout suitable for mobile devices.
 * 
 * 2. **Image Loading**:
 *    - Applies a blur transformation to images for faster initial loading. Actual images load once the user interacts 
 *      with them.
 * 
 * 3. **Lightbox Integration**:
 *    - Enables viewing of all images in a lightbox modal with zoom, counter, and thumbnails plugins.
 * 
 * 4. **Internationalization**:
 *    - Uses `react-i18next` to support dynamic text rendering for different languages.
 * 
 * Props:
 * - `apartment`: An object containing an array of image URLs.
 */

export default function MobileContainerPic({ apartment }) {
    // Cookie consent state
    const [cookies] = useCookies(['_iub_cs-s3824551']);

    // Translation hook for internationalization
    const { t } = useTranslation();
    
    // State for handling lightbox visibility and image index
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    return (
        <div style={{ margin: "30px 20px 0" }}>
            <Row className="mobile-images">
                <Col xs={12} className="mb-4">
                    <div className="image-and-button-container"     
                        onClick={() => {
                            if (cookies['_iub_cs-s3824551'] && cookies['_iub_cs-s3824551'].purposes[5]) {
                                ReactPixel.trackCustom('ViewPhotos', { apartmentName: apartment.name });
                            }
                            setIsOpen(true);
                        }}
                    >
                        <img 
                            src={apartment.images[0]} 
                            alt="Main Pic of Apartment" 
                            className="img-fluid rounded"
                        />
                        <div className="see-all-photos-button-mobile rounded">
                            {t('see_all_photos')}
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="mobile-images-row">
                <Col xs={6} className="mb-4">
                    <img 
                        src={apartment.images[1]} 
                        alt="Second Pic of Apartment" 
                        className="img-fluid rounded"
                    />
                </Col>
                <Col xs={6} className="mb-4">
                    <img 
                        src={apartment.images[2]} 
                        alt="Third Pic of Apartment" 
                        className="img-fluid rounded"
                    />
                </Col>
            </Row>

            {isOpen && (
                <Lightbox
                    slides={apartment.images.map(image => ({ src: image }))}
                    open={isOpen}
                    close={() => setIsOpen(false)}
                    index={photoIndex}
                    onIndexChange={setPhotoIndex}
                    plugins={[Counter, Thumbnails, Zoom]}
                    counter={{ container: { style: { top: 0 } } }}
                />
            )}
        </div>
    );
}